<template>
  <div class="contact-data fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-container>

          <v-row justify="center" class="mt-0">
            <v-col cols="12" class="mt-0 py-0">
              <div class="my-0 pt-0 primary--text">
                <h1 class="text-center">¡PREPARETE PARA SELFIE!</h1>
                <p class="primary--text text-center mb-0">Realiza una validación biométrica de tú cara para mayor seguridad en el proceso</p>
              </div>
            </v-col>
            <v-col cols="12" md="12" lg="12">


              <div v-show="isCameraOpen && isLoading" class="text-center">

              </div>

              <div v-if="isCameraOpen" v-show="!isLoading" class="mt-4 text-center" :class="{ 'flash' : isShotPhoto }">

                <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>

                <video v-show="!isPhotoTaken" ref="camera" :height="278" autoplay style="border-radius: 50px; transform: scale(-1, 1)"></video>

                <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :height="300" style="border-radius: 50px; transform: scale(-1, 1)"></canvas>

                <div v-if="!isPhotoTaken">
                  <div v-if="isCameraOpen && !isLoading" class="text-center mt-6">
                    <v-btn
                        @click="takePhoto"
                        color="primary"
                        class="px-6 text-capitalize today-bold text-h6"
                        elevation="0"
                        large>
                      Tomar foto
                    </v-btn>
                  </div>
                </div>

                <v-row justify="center" v-if="isPhotoTaken" class="mt-6">
                  <v-col cols="12" md="4" lg="3">
                    <v-btn
                        @click="takePhoto"
                        color="white"
                        block
                        class="px-6 text-capitalize today-bold text-h6 primary--text"
                        large>
                      Volver a capturar
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="4" lg="3">
                    <v-btn
                        @click="goPolicies"
                        color="primary"
                        block
                        class="px-6 text-capitalize today-bold text-h6"
                        elevation="0"
                        large>
                      Continuar
                    </v-btn>
                  </v-col>
                </v-row>

              </div>

            </v-col>
          </v-row>

        </v-container>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";

export default {
  name: 'DevPage',
  components: {LeftSideImage, NavBarPage},
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: '#'
    }
  },
  mounted() {
    if(this.isCameraOpen) {
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
    } else {
      this.isCameraOpen = true;
      this.createCameraElement();
    }
  },
  methods: {
    goPolicies() {
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
      this.$router.push('/polizas')
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true
      });

      navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch(error => {
            this.isLoading = false;
            alert("May the browser didn't support or there is some errors.");
          });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach(track => {
        track.stop();
      });
    },
    takePhoto() {
      if(!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext('2d');
      context.drawImage(this.$refs.camera, 0, 0, 380, 300);
    },

    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
          .replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);
    }
  }
}
</script>

<style scoped lang="scss">


</style>